<template>
  <div v-if="billing">

  <div class="my-5">

       <updateStatus v-if="billing.user && billing.user._id == user.id  || user.admin == true "    :billing="billing"/>

      
      <LabelBill :billing="billing" @add-label-success="addLabel" />
   
      <button
        type="button"
        @click="
          $router.push({ name: 'bill-view', query: { id: billing.invoice } })
        "
        class="btn btn-md btn-info"
      >
        Xem Mẫu Hóa Đơn
      </button>
            <!-- <button
        type="button"
        @click="SendEmail(billing)"
        class="btn btn-md btn-primary mx-2"
      >
        Gửi Email Cho Khách
      </button> -->

    </div>


     <h5 class="text-primary">
          Thông tin nhập vào
    </h5>
    <table class="table table-bordered">
      <tbody>
        <tr v-for="(item, index) in billing.product" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.lb }} (lb)</td>
           <td>{{ item.subtotal }} (subtotal)</td>
          <td>{{ item.discount }}(discount)</td>
        

          <td>
             {{ item.total }} (total)
          </td>
          <td>{{( billing.salary/2).toFixed(2)}} (nv)</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <h5 class="text-primary">Tóm tắt dịch vụ</h5>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Dịch vụ mua hộ</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p>Tên dịch vụ - [Tùy chọn]</p>
          </td>
          <td class="text-sm-end">
            {{ billing.service_name }}
          </td>
        </tr>
        <tr>
          <td>
            <p>Khối lượng mua hộ</p>
            <p>{{ billing.buystore.buy_lb }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Phí mua hộ</p>
            <p>
              {{ billing.buystore.price_buy }} {{ billing.buystore.disc_unit }}
            </p>
          </td>
        </tr>

        <tr>
          <td>
            <p>Cước mua hộ</p>
            <p>{{ billing.buystore.subtotal_buy }}</p>
          </td>
        </tr>

        <tr>
          <td>
            <p>Giảm giá mua hộ (-)</p>
            <p>{{ billing.buystore.disc_count_buy }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Phí tổng thu mua hộ (=)</p>
            <p>{{ billing.buystore.total_buy }}</p>
          </td>
        </tr>

        <tr>
          <td colspan="2" class="text-center">
            Tổng thu lưu kho và mua hộ : {{ billing.buystore.total }}
          </td>
        </tr>
      </tbody>
    </table>


  
    <div v-if="nLabel > 0" class="table-responsive">
      <div class="my-2">
          <LabelPrintAll :billing="billing" :nLabel="nLabel"/>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td
              v-for="(item, index) in nLabel"
              class="text-center h6"
              :key="'title' + index"
            >
              {{ item }}/{{ nLabel }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'invoice-' + index"
              class="font-size-12"
            >
              <p class="h6">Mã đơn hàng</p>
              {{ labelFormat(billing.invoice) }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'rep-' + index"
              class="font-size-12"
            >
              <p class="h6">Họ tên người nhận</p>
              {{ billing.recipient_fn }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'phone-' + index"
              class="font-size-12"
            >
              <p class="h6">Số điện thoại</p>
              {{ billing.recipient_phone }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'address-' + index"
              class="font-size-12"
            >
              <p class="h6">Địa chỉ người nhận</p>
              {{ billing.recipient_address }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'print-' + index"
              class="font-size-12"
            >
               <LabelPrint :billing="billing" :nLabel="nLabel" :index="index" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LabelBill from "@/components/billing/label.vue";
import LabelPrint from "@/components/billing/print.vue";
import LabelPrintAll from '@/components/billing/print-all.vue';
import updateStatus from "@/components/billing/update-status.vue";
var user = JSON.parse(localStorage.getItem("user"));
export default {
  components: {
    LabelBill,
    LabelPrint,
    updateStatus,
    LabelPrintAll
  },
  props: {
    billing: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      nLabel: 0,
      print: null,
      user: user
    };
  },

  methods: {
    labelFormat(label) {
      return label.replace("HT", "");
    },
    addLabel(nLabel) {
      this.nLabel = nLabel;
      location.reload(true);
    },
    SendEmail(billing) {
      axios({
        url:
          process.env.VUE_APP_SERVER_URL +
          "/api/bill/send-email/" +
          billing.invoice,
        method: "post",
        headers: {
          authorization: user.token,
        },
      })
        .then(({ data }) => {
          this.$toasted.success("Gửi mail thành công");
          return data;
        })
        .catch((error) => {
          error && error.message && error.response.data
            ? this.$toasted.error(
                error.response ? error.response.data : error.message
              )
            : this.$toasted.error("Lỗi không xác định xảy ra.");
        });
    },
  },
    mounted() {
    this.nLabel = this.billing.label > 0 ? this.billing.label : 0;
  },
};
</script>