<template>
  <div v-if="billing" style="display: inline-block">
    <button class="btn btn-outline-info btn-sm" @click="generateReport">
      Print all
    </button>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      :filename="'label-' + nLabel"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2PdfAll"
    >
      <section slot="pdf-content">
        <div v-for="label in nLabel" :key="'nlabel-' + label">
          <div class="m-5">
            <div class="m-5 p-5">
              <div class="row">
                <div class="col-4">
                  <p style="font-size: 28px">Nhãn Hóa Đơn</p>
                </div>
                <div class="col-8">
                  <p style="font-size: 38px">{{ label }}/{{ nLabel }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p style="font-size: 28px">Mã đơn hàng</p>
                </div>
                <div class="col-8">
                  <p style="font-size: 38px">
                    {{ labelFormat(billing.invoice) }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p style="font-size: 32px">Họ tên người nhận</p>
                </div>
                <div class="col-8">
                  <p style="font-size: 32px">{{ billing.recipient_fn }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p style="font-size: 32px">Số điện thoại</p>
                </div>
                <div class="col-8">
                  <p style="font-size: 32px">{{ billing.recipient_phone }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p style="font-size: 32px">Địa chỉ người nhận</p>
                </div>
                <div class="col-8">
                  <p style="font-size: 32px">{{ billing.recipient_address }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="html2pdf__page-break"></div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  methods: {
    generateReport() {
      this.$refs.html2PdfAll.generatePdf();
      this.index = this.index;
    },
    labelFormat(label) {
      return label.replace("HT", "");
    },
  },

  components: {
    VueHtml2pdf,
  },
  props: {
    billing: {
      type: Object,
      default: null,
    },
    nLabel: {
      type: Number,
      default: 0,
    },
  },
};
</script>