<template>
  <span v-if="billing">
    <!-- <button
      type="button"
      @click="setStatus(billing.status, 'false')"
      class="btn btn-md btn-danger mx-2"
    >
      Hủy đơn
    </button> -->

    <button
      type="button"
      v-if="billing.status !=='true'"
      class="btn btn-md btn-primary mx-2"
        @click="showModal"
    >
        Cập nhập tình trạng
    </button>
    <button
      type="button"
      v-else="billing.status == 'true'"
      class="btn btn-md btn-success mx-2"
        @click="showModal"
    >
       Đơn đã giao thành công
    </button>

    <!-- <button
      type="button"
      @click="showModal"
      class="btn btn-md btn-primary mx-2"
    >
     Cập nhập hóa đơn
    </button> -->

    <b-modal
      ref="my-modal-update"
      style="height: 300px"
      hide-footer
      title="Cập nhập hóa đơn"
    >
      <div class="d-block text-center">
        <b-form-select class="mt-3 form-control" v-model="selected" :options="options"></b-form-select>

        <b-form-input
          type="text"
          v-model="img"
          class="mt-3"
          placeholder="Link ảnh giao thành công"
        ></b-form-input>

        <b-form-input
          type="text"
          v-model="noted"
          class="mt-3"
          placeholder="Ghi chú đính kèm"
        ></b-form-input>
      </div>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        v-if="billing.status =='null'"
        @click="createLabel"
        >Cập nhập tình trạng</b-button
      >
       <b-button
        class="mt-3"
        variant="success"
        disabled
        v-if="billing.status =='true'"
        >Đơn đã hoàn thành</b-button
      >
       <b-button
        class="mt-3"
        variant="danger"
        disabled
        v-if="billing.status =='false'"
        >Đơn đã hủy</b-button
      >
    </b-modal>
  </span>
</template>

<script>
import axios from "axios";
var user = JSON.parse(localStorage.getItem("user"));
export default {
  props: {
    billing: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      noted: null,
      img: null,
      options: [
        { value: "null", text: "Đơn đang vận chuyển" },
        { value: "false", text: "Đơn bị hủy" },
        { value: "true", text: "Đơn giao thành công" },
      ],
    };
  },
  methods: {
    setStatus(bill_status, status) {
      switch (bill_status) {
        case "null":
          this.selected = status;
          this.createLabel();
          break;
        case "true":
          this.$toasted.success("Giao hàng đã thành công");
          break;
        case "false":
          this.$toasted.error("Đơn đã bị hủy");
          break;
      }
    },
    createLabel() {
      axios({
        url: process.env.VUE_APP_SERVER_URL + "/api/bill/update/status",
        method: "post",
        headers: {
          authorization: user.token,
        },
        data: {
          _id: this.billing._id,
          status: this.selected,
          noted: this.noted,
          img: this.img
        },
      })
        .then(({ data }) => {
          if (this.selected == "false") {
            this.$toasted.error("Cập nhập đơn hủy");
          } else {
            this.$toasted.success("Cập nhập đơn hàng thành công");
          }

          // this.$refs["my-modal-update"].hide();

          this.$router.push({
            path:"/danh-sach-hoa-don"
          });

          return data;
        })
        .catch((error) => {
          error && error.message && error.response.data
            ? this.$toasted.error(
                error.response ? error.response.data : error.message
              )
            : this.$toasted.error("Lỗi không xác định xảy ra.");
        });
    },
    showModal() {
      this.$refs["my-modal-update"].show();
    },
    hideModal() {
      this.$refs["my-modal-update"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal-update"].toggle("#toggle-btn");
    },
  },
  mounted() {
    this.noted = this.billing.noted;
    this.selected = this.billing.status;
    console.log(this.billing.status);
  },
};
</script>