<template>
  <span v-if="billing">
    <button
      type="button"
      @click="showModal"
      class="btn btn-md btn-primary mx-2"
    >
      Tạo nhãn hóa đơn
    </button>

    <b-modal
      ref="my-modal"
      style="height: 300px"
      hide-footer
      title="Nhập số nhãn cần tạo"
    >
      <div class="d-block text-center">
        <b-form-input
          type="number"
          min="0"
          max="100"
          v-model="n"
          placeholder="Nhập số lượng label cần tạo"
        ></b-form-input>
      </div>
      <b-button
        class="mt-3"
        variant="outline-success"
        block
        @click="createLabel"
        >Tạo nhãn</b-button
      >
    </b-modal>
  </span>
</template>

<script>
import axios from "axios";
var user = JSON.parse(localStorage.getItem("user"));
export default {
  props: {
    billing: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      n: 0,
    };
  },
  methods: {
    createLabel() {
      if( this.n>39){
          this.$toasted.error("Vui lòng nhập số lượng nhỏ hơn 40");
          return;
          
      }
      axios({
        url:
          process.env.VUE_APP_SERVER_URL + "/api/bill/label/add",
        method: "post",
        headers: {
          authorization: user.token,
        },
        data:{
            _id: this.billing._id,
            label: this.n
        }
      })
        .then(({ data }) => {
          this.$toasted.success("Tạo số lượng nhãn mới thành công");
          this.$emit("add-label-success",this.n)
          this.$refs["my-modal"].hide();
          return data;
        })
        .catch((error) => {
          error && error.message && error.response.data
            ? this.$toasted.error(
                error.response ? error.response.data : error.message
              )
            : this.$toasted.error("Lỗi không xác định xảy ra.");
        });

    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
};
</script>