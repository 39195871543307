<template>
  <div v-if="billing">
      <div class="my-5">

       <updateStatus v-if="billing.user && billing.user._id == user.id  || user.admin == true "    :billing="billing"/>

      <LabelBill :billing="billing" @add-label-success="addLabel" />

      <!-- <button
        type="button"
        @click="SendEmail(billing)"
        class="btn btn-md btn-primary mx-2"
      >
        Gửi Email Cho Khách
      </button> -->



      <button
        type="button"
        @click="
          $router.push({ name: 'bill-view', query: { id: billing.invoice } })
        "
        class="btn btn-md btn-info"
      >
        Xem Mẫu Hóa Đơn
      </button>
    </div>
     <h5 class="text-primary">
          Thông tin nhập vào
    </h5>
    <!-- <pre>{{ billing.product}}</pre> -->
    <table class="table table-bordered">
      <tbody>
        <tr v-for="(item, index) in billing.product" :key="index">
          <td>{{ item.name }}</td>
          <td>Giá {{ item.price }}</td>
          <td>
            Giảm giá {{ item.disc }}{{ item.unit }}
          </td>
          <td>Khối lượng {{ item.lb }}</td>
          <td>Phi dich vu {{ item.subtotal }}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <h5 class="text-primary">Tóm tắt dịch vụ</h5>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>Tên dịch vụ - [Tùy chọn]</td>
          <td class="text-sm-end">
            {{ billing.service_name }}
            {{ billing.service_opition ? `[${billing.service_opition}]` : `` }}
          </td>
        </tr>
        <tr>
          <td>Khối lượng chuyển</td>
          <td class="text-sm-end">{{ billing.total_weight }}</td>
        </tr>
        <tr>
          <td>Phí vận chuyển</td>
          <td class="text-sm-end">{{ billing.shipping_fee }}</td>
        </tr>

        <tr>
          <td>phí dịch vụ</td>
          <td class="text-sm-end">{{ billing.service_price }}</td>
        </tr>

         <tr>
          <td>Phí phụ</td>
          <td class="text-sm-end">{{ billing.total_tax }}</td>
        </tr>
           <tr>
          <td>Tổng phí dịch vụ</td>
          <td class="text-sm-end">{{ billing.subtotal }}</td>
        </tr>
        <tr>
          <td>Tổng Giảm giá (-)</td>
          <td class="text-sm-end">{{ billing.discount }}</td>
        </tr>
        <tr>
          <td>Phí thu (=)</td>
          <td class="text-sm-end">{{ billing.total_changer }}</td>
        </tr>
      </tbody>
    </table>

    <hr />
  

    <div v-if="nLabel > 0" class="table-responsive">
      <div class="my-2">
          <LabelPrintAll :billing="billing" :nLabel="nLabel"/>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td
              v-for="(item, index) in nLabel"
              class="text-center h6"
              :key="'title' + index"
            >
              {{ item }}/{{ nLabel }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'invoice-' + index"
              class="font-size-12"
            >
              <p class="h6">Mã đơn hàng</p>
              {{ billing.invoice }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'rep-' + index"
              class="font-size-12"
            >
              <p class="h6">Họ tên người nhận</p>
              {{ billing.recipient_fn }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'phone-' + index"
              class="font-size-12"
            >
              <p class="h6">Số điện thoại</p>
              {{ billing.recipient_phone }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'address-' + index"
              class="font-size-12"
            >
              <p class="h6">Địa chỉ người nhận</p>
              {{ billing.recipient_address }}
            </td>
          </tr>
          <tr>
            <td
              v-for="index in nLabel"
              :key="'print-' + index"
              class="font-size-12"
            >
              <LabelPrint :billing="billing" :nLabel="nLabel" :index="index" />
          
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LabelBill from "@/components/billing/label.vue";
import LabelPrint from "@/components/billing/print.vue";
import updateStatus from "@/components/billing/update-status.vue";
import LabelPrintAll from '@/components/billing/print-all.vue';
var user = JSON.parse(localStorage.getItem("user"));
export default {
  components: {
    LabelBill,
    LabelPrint,
    updateStatus,
    LabelPrintAll
  },
  props: {
    billing: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      nLabel: 0,
      print: null,
          user: user
    };
  },

  methods: {
    labelFormat(label) {
      return label.replace("HT", "");
    },
    addLabel(nLabel) {
      this.nLabel = nLabel;
      location.reload(true);
    },
    SendEmail(billing) {
      axios({
        url:
          process.env.VUE_APP_SERVER_URL +
          "/api/bill/send-email/" +
          billing.invoice,
        method: "post",
        headers: {
          authorization: user.token,
        },
      })
        .then(({ data }) => {
          this.$toasted.success("Gửi mail thành công");
          return data;
        })
        .catch((error) => {
          error && error.message && error.response.data
            ? this.$toasted.error(
                error.response ? error.response.data : error.message
              )
            : this.$toasted.error("Lỗi không xác định xảy ra.");
        });
    },
  },
    mounted() {
    this.nLabel = this.billing.label > 0 ? this.billing.label : 0;
  },
};
</script>