<template>
  <div v-if="billing && index" style="display: inline-block; margin-right: 5px">
    <button class="btn btn-outline-info btn-sm" @click="generateReport">
      Print
    </button>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="index + '/' + nLabel"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-5">
      
        <div div class="row">
          <div class="col"><p style="font-size: 32px">Nhãn Hóa Đơn</p></div>
          <div class="col">
            <p style="font-size: 60px">{{ index }}/{{ nLabel }}</p>
          </div>
        </div>
        <div div class="row">
          <div class="col"><p style="font-size: 32px">Mã đơn hàng</p></div>
          <div class="col">
            <p style="font-size: 60px">{{ labelFormat(billing.invoice) }}</p>
          </div>
        </div>
        <div div class="row">
          <div class="col">
            <p style="font-size: 32px">Họ tên người nhận</p>
          </div>
          <div class="col">
            <p style="font-size: 32px">{{ billing.recipient_fn }}</p>
          </div>
        </div>
        <div div class="row">
          <div class="col"><p style="font-size: 32px">Số điện thoại</p></div>
          <div class="col">
            <p style="font-size: 32px">{{ billing.recipient_phone }}</p>
          </div>
        </div>
        <div div class="row">
          <div class="col">
            <p style="font-size: 32px">Địa chỉ người nhận</p>
          </div>
          <div class="col">
            <p style="font-size: 32px">{{ billing.recipient_address }}</p>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
      this.index = this.index;
    },
    labelFormat(label) {
      return label.replace("HT", "");
    },
  },

  components: {
    VueHtml2pdf,
  },
  props: {
    billing: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    nLabel: {
      type: Number,
      default: 0,
    },
  },
};
</script>