<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import vnus from "@/components/billing/details/vnus.vue";
import usvn from "@/components/billing/details/usvn.vue";
import usus from "@/components/billing/details/usus.vue";

import seaway from "@/components/billing/details/seaway.vue";
import money from "@/components/billing/details/money.vue";
import buystore from "@/components/billing/details/buystore.vue";
import shopping from "@/components/billing/details/shopping.vue";

let user = JSON.parse(localStorage.getItem("user"));
/**
 * Invoice-detail component
 */
export default {
  page: {
    title: "Chi tiết hóa đơn thanh toán",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vnus,
    usvn,
    usus,
    seaway,
    money,
    buystore,
    shopping,
  },
  data() {
    return {
      title: "Chi tiết hóa đơn thanh toán",
      items: [
        {
          text: "Trang chủ",
          href: "/",
        },
        {
          text: "Chi tiết hóa đơn",
          active: true,
        },
      ],
      billing: null,
      user: user
    };
  },

  methods: {
    deleteBill(_id) {
      axios({
        url: process.env.VUE_APP_SERVER_URL + "/api/bill/delete",
        method: "post",
        headers: {
          authorization: user.token,
        },
        data: {
          _id: _id,
        },
      })
        .then(({ data }) => {
          if (data) {
            this.$toasted.success("Xóa bản ghi thành công");
            this.$router.push("/danh-sach-hoa-don");
          }
        })
        .catch((err) => {
          this.$toasted.error("Có lỗi không thể xóa bản ghi này.");
        });
    },
  },

  created() {
    if (!this.$route.query.id) {
      this.$router.push("/");
    }
    let _id = this.$route.query.id;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/bill/info",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          this.billing = data;
        } else {
          this.$toasted.error("Không tìm thấy hóa đơn phù hợp");
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.$toasted.error("Không tìm thấy hóa đơn phù hợp");
        this.$router.push("/");
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12" v-if="billing">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <h4 class="float-end font-size-16">
                Xử lý bởi {{ billing.user.username }}
              </h4>
              Đơn hàng ID #{{ billing.invoice }}
            </div>
            <hr />

            <div>
              <div v-if="billing.type == 1">
                <usvn :billing="billing"></usvn>
              </div>
              <div v-if="billing.type == 2">
                <vnus :billing="billing"></vnus>
              </div>
              <div v-if="billing.type == 3">
                <usus :billing="billing"> </usus>
              </div>
              <div v-if="billing.type == 4">
                <seaway :billing="billing"></seaway>
              </div>
              <div v-if="billing.type == 5">
                <money :billing="billing"></money>
              </div>
              <div v-if="billing.type == 6">
                <buystore :billing="billing"></buystore>
              </div>
              <div v-if="billing.type == 7">
                <shopping :billing="billing"> </shopping>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <h5 class="text-primary">Tóm tắt hóa đơn</h5>

                <div class="table-responsive muted">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center" width="50%">
                          Thông tin người gửi
                        </th>
                        <th class="text-center" width="50%">
                          Thông tin người nhận
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p><b>Số điện thoại người gửi</b></p>
                          <p>{{ billing.sender_phone }}</p>
                        </td>
                        <td class="text-sm-end">
                          <p><b>Số điện thoại người nhận</b></p>
                          <p>{{ billing.recipient_phone }}</p>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p><b>Họ tên người gửi</b></p>
                          <p>{{ billing.sender_fn }}</p>
                        </td>
                        <td class="text-sm-end">
                          <p><b>Họ tên người nhận</b></p>
                          <p>{{ billing.recipient_fn }}</p>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p><b>Địa chỉ e-mail người gửi</b></p>
                          <p>{{ billing.sender_email }}</p>
                        </td>
                        <td class="text-sm-end">
                          <p><b>Địa chỉ email người nhận</b></p>
                          <p>{{ billing.recipient_email }}</p>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p><b>Địa chỉ người gửi</b></p>
                          <p>{{ billing.sender_address }}</p>
                        </td>
                        <td class="text-sm-end">
                          <p><b>Địa chỉ người nhận</b></p>
                          <p>{{ billing.recipient_address }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />

                  <div v-if="billing.img">
                      <p>Ảnh giao hàng</p>
                      <div>
                         <img class="img-fluid" :src="billing.img"/>
                      </div>
                  </div>
                   <div v-if="billing.noted">
                      <p>Ghi chú </p>
                      <div>
                          {{billing.noted}}
                      </div>
                  </div>
                  <div v-if="user.admin == true" class="mt-2 remove-bill">
                    <button type="button" @click="deleteBill(billing._id)" class="btn btn-danger">Xóa Hóa Đơn</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

